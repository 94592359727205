<template>

  <div class="col-lg-4 col-md-6">
    <div class="single-pricing overly-one">
      <div class="overly-two">
        <div class="pricing-title">
          <div class="price-on-sale" v-if="product.discount"><span>{{product.discount}}%</span>Discount</div>
          <div class="price-on-sale" v-if="!product.discount && (product.price != product.priceRegular)">Reduced price</div>
          <div class="price-on-sale-normal" v-if="!product.discount && (product.price == product.priceRegular)">Regular price</div>

          <div class="price-allnumbers">
            <sub>{{ currencyChar }} </sub>{{ formattedPrice.price.intNumber }}
            <span v-if="formattedPrice.price.cents" class="cents">.{{ formattedPrice.price.cents }}</span>
            <sub v-if="product.price != product.priceRegular" class="orgPrice">
              <span v-if="!formattedPrice.priceRegular.cents">{{ currencyChar }} {{ formattedPrice.priceRegular.intNumber }}</span>
              <span v-if="formattedPrice.priceRegular.cents">{{ currencyChar }} {{ formattedPrice.priceRegular.allFormatted }}</span>
            </sub>
            <sub v-if="product.type === 'subscription'" class="perMonth">/mo</sub>
          </div>



          <span v-if="product.type === 'subscription'">

            <p v-if="product.months == 1">billed monthly</p>
            <p v-else-if="product.months == 3">billed quarterly</p>
            <p v-else-if="product.months == 12">billed yearly</p>
            <p v-else>billed every {{product.months}} months</p>

          </span>
          <span v-if="product.type === 'onetimepayment'">access for {{product.months}} months</span>

        </div>

        <ul>
          <li>more than {{sectionCatItem.minAmountOfQuestions}} questions</li>
          <li>{{sectionCatItem.sections.length}} topics</li>
          <li>strength analysis</li>
          <li>smartphone compatible</li>

        </ul>

        <div v-if="product.type === 'subscription'" class="paymentInfoBox">
          <span v-if="product.months == 1">You will be charged <strong>{{formattedPrice.recurringFeeFormatted}}</strong> {{product.currency}} <strong>every month</strong>.</span>
          <span v-else>You will be charged <strong>{{formattedPrice.recurringFeeFormatted}}</strong> {{product.currency}} <strong>every {{product.months}} months</strong>.</span>
          <span>You can cancel the subscription any time.</span>
          <span v-if="product.currency == 'EUR'">Price includes tax.</span>
          <span v-if="product.currency == 'USD'">Additional taxes according to region.</span>
          <span>60 days return period</span>
        </div>

        <div v-if="product.type === 'onetimepayment'" class="paymentInfoBox">
          <span>No recurring fee.</span>
          <span>Access for {{product.months}} months.</span>
          <span v-if="product.currency == 'EUR'">Price includes tax.</span>
          <span v-if="product.currency == 'USD'">Additional taxes according to region.</span>
          <span>60 days return period</span>
        </div>

        <div class="buyForSomeoneElseInfoBox" :class="{ 'default-padding': !buyForSomeoneElse, 'expanded-padding': buyForSomeoneElse }">
          <div class="form-check form-switch">
            <div>
              <input class="form-check-input" type="checkbox" id="buyForSomeoneElse" v-model="this.buyForSomeoneElse">
              <label class="form-check-label" for="buyForSomeoneElse">I want to buy it for someone else.</label>
            </div>
          </div>

          <div v-if="this.buyForSomeoneElse">
            Please enter the email address of the person below, who shall receive the product.
            You won't see the order in your order-history page on this site but the recipient. The recipient will not see
            how much you paid or how you paid for it. After the order, you will receive an email with the order details and URLs to manage your order.
          </div>
          <div class="email-input-container" v-if="this.buyForSomeoneElse">
            <input type="email" class="form-control email-input" id="buyForSomeoneElseEmailInput" placeholder="Email of the recipient" v-model="this.buyForSomeoneElseEmail">
          </div>
          <div class="invalidEmailAddress" v-if="buyForSomeoneElse && !buyForSomeoneElseEmailValid && buyForSomeoneElseEmail.length > 0">invalid email address!</div>
          <div class="invalidEmailAddress" v-if="buyForSomeoneElse && !buyForSomeoneElseEmailValid && clickedOnceOnOrderNowButton">requires email address!</div>
        </div>


        <a href="#" @click.prevent="orderNow" class="default-btn">
          <span>Order now</span>
        </a>

        <div class="pricing-shape">
          <img/>
        </div>
        <div class="pricing-shape-2">
          <img/>
        </div>
      </div>
    </div>
  </div>

</template>


<script>


export default {
  props: ['product','sectionCatItem'],

  data() {
    return {
      buyForSomeoneElse: false,
      buyForSomeoneElseEmail: "",
      buyForSomeoneElseEmailValid: false,
      clickedOnceOnOrderNowButton: false,
    }
  },

  watch: {
    buyForSomeoneElseEmail(newEmail) {
      // Remove all whitespaces from the email address
      const trimmedEmail = newEmail.replace(/\s+/g, '');
      this.buyForSomeoneElseEmail = trimmedEmail;

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.buyForSomeoneElseEmailValid = emailRegex.test(trimmedEmail);

    }
  },


  computed: {

    currencyChar(){
      let character = '€';
      if (this.product.currency === 'USD') {character = '$'}
      return character;
    },

    formattedPrice() {
      // Function to format price
      const formatPrice = (price) => {
        const hasCents = price % 1 !== 0; // Check if there are cents
        const intNumber = Math.floor(price);
        const cents = hasCents ? (price - intNumber).toFixed(2).substring(2) : '';
        const allFormatted = hasCents ? `${intNumber}.${cents}` : intNumber.toString();

        return {
          intNumber: intNumber.toString(),
          cents,
          allFormatted
        };
      };

      const recurringFeeFormatted = (this.product.price * this.product.months).toFixed(2);

      return {
        price: formatPrice(this.product.price),
        priceRegular: formatPrice(this.product.priceRegular),
        recurringFeeFormatted: recurringFeeFormatted
      };
    },
  },


  methods: {
    orderNow() {

      // if buyForSomeoneElseEmailValid is false, mark the input field buyForSomeoneElseEmailInput as invalid
      if (this.buyForSomeoneElse && !this.buyForSomeoneElseEmailValid) {
        this.clickedOnceOnOrderNowButton = true;
      }


      if (this.buyForSomeoneElse && !this.buyForSomeoneElseEmailValid) {
        return; // Stop the function if conditions are not met
      }

      let baseUrl = '';
      // https://www.checkout-ds24.com/product/551005/?plan=1120846&voucher=TEST01&custom=foo%40bar.de
      baseUrl = `https://www.checkout-ds24.com/product/${this.product.digistoreProductId}?plan=${this.product.id}&currency=${this.product.currency}&hide_plans=true`;

      // Initialize an array to hold query parameters
      let queryParams = [];

      // Check for buyForSomeoneElse condition and email validity
      if (this.buyForSomeoneElse && this.buyForSomeoneElseEmailValid) {
        // Clear whitespaces from the email
        const emailParam = this.buyForSomeoneElseEmail.replace(/\s+/g, '');
        // Add custom parameter to the array
        queryParams.push(`custom=${encodeURIComponent(emailParam)}`);
      }

      // Check for product.coupon existence
      if (this.product.coupon) {
        // Add coupon parameter to the array
        queryParams.push(`voucher=${encodeURIComponent(this.product.coupon)}`);
      }

      // Append query parameters to baseUrl if any exist
      if (queryParams.length > 0) {
        baseUrl += `&${queryParams.join('&')}`;
      }

      console.log("redirect to: "+baseUrl)
      // Navigate to the constructed URL
      window.location.href = baseUrl;
    }


  },
}
</script>