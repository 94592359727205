<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Affiliate</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Info</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="affiliate-info-area">

        <h1>Become a Bits & Bytes affiliate!</h1>

        <p>Earn with Bits & Bytes by driving sales! Join our affiliate program and secure a 40% commission on each product sold through your referrals, applicable for the entire subscription period or via a one-time payment.</p>

        <div class="affiliate-about-media-preview">

          <video controls :poster="require('@/assets/img/affiliate/preview/aff01-preview.jpg')">
            <source src="https://bitsandbytes.academy/stream/videos/aff01.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>

        </div>

        <h2>How it works</h2>

        <div class="affiliate-info-123">
          <dl>
            <dt>Join the program</dt>
            <dd><a href="https://www.digistore24.com/signup/551005/" target="_blank">Register as affiliate at Digistore24</a> and create your <router-link to="/af_link_gen">affiliate link</router-link>.</dd>
          </dl>
          <dl>
            <dt>Promote</dt>
            <dd>Reach out your network and promote by using the <router-link to="/af_link_gen">generated link</router-link>.</dd>
          </dl>
          <dl>
            <dt>Get money</dt>
            <dd>Check your commission at Digistore24 and withdraw money.</dd>
          </dl>

        </div>

        <div class="affiliate-info-facts">
          <h2>Outstanding benefits</h2>
          <ul>
            <li>
              <i class="bx bx-check-circle"></i>
              40% commission (after discounts and payment fees)
            </li>
            <li>
              <i class="bx bx-check-circle"></i>
              Long term promotion! Affiliate cookies last for 180 days!
            </li>
            <li>
              <i class="bx bx-check-circle"></i>
              Continued revenue share for subscriptions.
            </li>
            <li>
              <i class="bx bx-check-circle"></i>
              Last cookie wins; If you are the last convincing the customer, you make the profit.
            </li>
          </ul>
        </div>


        <div class="affiliate-media">
          <h2>Footage & material</h2>

          <p>Feel free to use this promo material for your affiliate program, for example in your own YouTube videos or website.</p>

          <!-- videos -->

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <video controls :poster="require('@/assets/img/affiliate/preview/trailer01-45s-preview.jpg')">
                <source src="https://bitsandbytes.academy/stream/videos/trailer01-45s.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Main trailer 01</li>
                <li>with music</li>
                <li>no talking</li>
              </ul>
            </div>
            <div
                class="affiliate-media-item-download"
                @click="triggerDownload($event)"
                data-download-url="https://bitsandbytes.academy/downloads/videos/trailer01-45s.mp4">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <video controls :poster="require('@/assets/img/affiliate/preview/short01-57s-preview.jpg')">
                <source src="https://bitsandbytes.academy/stream/videos/short01-57s.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Short 01</li>
                <li>with music</li>
                <li>talking</li>
              </ul>
            </div>
            <div
                class="affiliate-media-item-download"
                @click="triggerDownload($event)"
                data-download-url="https://bitsandbytes.academy/downloads/videos/short01-57s.mp4">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>


          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <video controls :poster="require('@/assets/img/affiliate/preview/short02-57s-preview.jpg')">
                <source src="https://bitsandbytes.academy/stream/videos/short02-57s.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Short 02</li>
                <li>with music</li>
                <li>talking</li>
              </ul>
            </div>
            <div
                class="affiliate-media-item-download"
                @click="triggerDownload($event)"
                data-download-url="https://bitsandbytes.academy/downloads/videos/short02-57s.mp4">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <video controls :poster="require('@/assets/img/affiliate/preview/short03-57s-preview.jpg')">
                <source src="https://bitsandbytes.academy/stream/videos/short03-57s.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Short 03</li>
                <li>with music</li>
                <li>talking</li>
              </ul>
            </div>
            <div
                class="affiliate-media-item-download"
                @click="triggerDownload($event)"
                data-download-url="https://bitsandbytes.academy/downloads/videos/short03-57s.mp4">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>


          <!-- images -->
          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/linux-adv-01-preview.jpg" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/linux-adv-01.jpg">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/linux-adv-02-preview.jpg" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/linux-adv-02.jpg">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/linux-adv-03-preview.jpg" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/linux-adv-03.jpg">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/linux-adv-04-preview.jpg" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/linux-adv-04.jpg">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/onsmartphone-preview.webp" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/onsmartphone.webp">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/skills-02c-preview.webp" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/skills-02c.webp">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>

          <!-- item -->
          <div class="affiliate-media-item">
            <div class="affiliate-media-preview">
              <img src="../../assets/img/affiliate/preview/syntax-preview.webp" alt="Image">
            </div>
            <div class="affiliate-media-item-desc">
              <ul>
                <li>Image</li>
              </ul>
            </div>
            <div class="affiliate-media-item-download" @click="triggerDownload($event)" data-download-url="https://bitsandbytes.academy/downloads/images/syntax.webp">
              <font-awesome-icon icon="fa-sharp fa-solid fa-download" />
              <p>download</p>
            </div>
          </div>



        </div>

      </div>

    </div>
  </section>


</template>



<script>
export default {
  name: 'AffiliateMedia',
  methods: {
    triggerDownload(event) {
      const downloadUrl = event.currentTarget.getAttribute('data-download-url');
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = downloadUrl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },



}

</script>