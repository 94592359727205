<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Affiliate</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Link Generator</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->





  <section class="standard-text-page-area">
    <div class="container">
      <div class="affiliate-link-gen-area">

        <h1>Great that you want to promote Bits & Bytes!</h1>

        <p>The link generator needs your Affiliate-ID to create a unique URL with which the sales can be assigned to you.</p>


        <div class="affiliate-link-gen-inblock">
          <h2>Enter your affiliate ID below.</h2>
          <p>You need to <a href="https://www.digistore24.com/signup/551005/" target="_blank">register as affiliate at Digistore24</a> to get the id.</p>
          <div>
            <input type="text" id="affiliateID" v-model="affiliateID" placeholder="Your Affiliate ID">
          </div>
        </div>

        <div class="affiliate-link-gen-inblock" v-if="affiliateUrl1">
          <h2>Here is the resulting URL. Copy it and use it for promotion.</h2>
          

          <p>Will redirect to product page "advanced Linux Training":</p>
          <div class="input-group">
            <input type="text" id="affiliateUrl1" :value="affiliateUrl1" class="affiliate-link-gen-result" readonly>
            <font-awesome-icon  icon="fa-sharp fa-regular fa-clipboard" @click="copyToClipboard('#affiliateUrl1')"/>

          </div>
        </div>

      </div>
    </div>
  </section>


</template>



<script>


export default {
  data() {
    return {
      affiliateID: "",
      baseUrl2: "https://bitsandbytes.academy/#/af/lxadv/",
    }
  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },

  setup() {
    const copyToClipboard = (selector) => {
      const el = document.querySelector(selector);
      if (el) {
        navigator.clipboard.writeText(el.value).then(() => {
          // Optional: Display a message or change the icon to indicate the text was copied.
          console.log('Text copied to clipboard');
        }).catch(err => {
          console.error('Could not copy text:', err);
        });
      }
    };

    return {
      copyToClipboard
    };
  },


  computed: {

    affiliateUrl1() {
      // Trim whitespaces at the start/end and remove all spaces in between
      const cleanedAffiliateID = this.affiliateID.replace(/\s+/g, '');
      return cleanedAffiliateID ? `${this.baseUrl2}${cleanedAffiliateID}` : '';
    },
  }

}

</script>