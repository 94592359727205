<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Affiliate</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">Loading ...</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->


  <section class="standard-text-page-area">
    <div class="container">
      <div class="order-completed-area">

        <h1>one moment please  ...</h1>

      </div>
    </div>
  </section>


</template>



<script>

export default {
  inject: ['sectionCat'],

  mounted() {
    const params = this.$route.params.afParams;
    const parts = params.split('/');
    console.log("Affiliate parts: " + parts);

    let targetAlias, affiliateId;

    if (parts.length === 2) {
      targetAlias = parts[0];
      affiliateId = parts[1];
    } else if (parts.length === 1) {
      targetAlias = "h";
      affiliateId = parts[0];
    } else {
      console.log("Affiliate - Redirect to Home if parts array is empty or has more than 2 entries");
      this.$router.push({ name: 'Home' });
      return; // Make sure to exit the function to prevent further execution
    }
    if (affiliateId === ""){
      console.log("Affiliate - Redirect to Home as no affiliateId");
      this.$router.push({ name: 'Home' });
      return; // Make sure to exit the function to prevent further execution
    }

    const targetAliases = {
      lxadv: "https://www.checkout-ds24.com/redir/551005/[affiliateID]/",
    };

    let targetUrlRaw = targetAliases[targetAlias];

    // Check if targetAlias exists in targetAliases to prevent undefined errors
    if (!targetUrlRaw) {
      console.log("Affiliate - Redirect to Home as no matching alias found");
      this.$router.push({ name: 'Home' });
      return;
    }

    let targetUrl = targetUrlRaw.replace("[affiliateID]", affiliateId);
    console.log("redirecting to: "+targetUrl)
    // Redirect the browser to targetUrl
    window.location.href = targetUrl;
  },


  created() {

    window.scrollTo(0,0); // scroll to top

  },

  methods: {
  },

  computed:{

  }

}

</script>